<template>
    <app-search-select-multiple-table
        :key="field.camelSingular"
        v-model="valueLocal"
        :entity-key="entityKey"
        :label="removeIds(field.titleSingular)"
        :rules="field.rules"
        :readonly="isReadonly"
        :required="field.isRequired"
        :disabled="isDisabled"
        :custom-filter="customFilter" />
</template>

<script>
export default {
    components: {
        appSearchSelectMultipleTable: () => import("./AppSearchSelectMultipleTable.vue"),
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        customFilter: {
            type: Object,
            default: null,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        entityKey() {
            return this.removeIds(this.field.camelSingular);
        }
    },
    methods: {
        removeIds(value) {
            return value.substring(0, value.indexOf("Ids"));
        }
    },
};
</script>
